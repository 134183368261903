
import Vue from "vue";
// import { Card } from "element-ui";
import QuickLinks from "@/components/QuickLinks/QuickLinks.vue";
export default Vue.extend({
  components: { QuickLinks },
  data() {
    return {
      cellsToShow: ["property", "billing", "payPlan"]
    };
  }
});
