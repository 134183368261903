
import { getSystemFilesURL } from "@/helpers";
import Vue from "vue";

export default Vue.extend({
  name: "QuickLinks",
  props: {
    cellsToShow: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  computed: {
    getClaimsImgUrl: () => getSystemFilesURL("claims"),
    getBillingChangeImgUrl: () => getSystemFilesURL("billing-change"),
    getPropertyChangeImgUrl: () => getSystemFilesURL("property-change"),
    getPayPlanImgUrl: () => getSystemFilesURL("pay-plan"),
    getQuoteHomeImgUrl: () => getSystemFilesURL("quote-home"),
    getBillPayImgUrl: () => getSystemFilesURL("bill-pay")
  }
});
